<template>
  <KTCard
    :page="page"
    :isForm="true"
    :isEdit="isEdit"
    @submit="onSubmit"
    :isLoading="ApiIsLoading"
    :cancelTo="parentRoute"
    :readonly="finalizado"
  >
    <template #body>
      <b-form-group label-size="lg" label-cols-md="4" label-cols-lg="3">
        <template #label>
          Descrição <span class="text-danger">*</span>
        </template>
        <template #default>
          <b-form-input
            size="lg"
            v-model="form.descricao"
            placeholder="Digite a descrição"
            autocomplete="off"
            :required="!finalizado"
            :disabled="finalizado"
          />
        </template>
      </b-form-group>
      <b-form-group
        label-size="lg"
        label-cols-md="4"
        label-cols-lg="3"
        :class="{ 'mb-0': isPP }"
      >
        <template #label>
          Unidade de medida <span class="text-danger">*</span>
        </template>
        <template #default>
          <b-form-input
            size="lg"
            v-model="form.nome_unidade_medida"
            placeholder="Digite a unidade de medida"
            autocomplete="off"
            :required="!finalizado"
            :disabled="finalizado"
          />
        </template>
      </b-form-group>
      <b-form-group
        label-size="lg"
        label-cols-md="4"
        label-cols-lg="3"
        class="mb-0"
        v-if="!isPP"
      >
        <template #label>
          Perda em processo <span class="text-danger">*</span>
        </template>
        <template #default>
          <b-input-group size="lg" append="%">
            <KTInputNumeric
              v-model="form.perda_em_processo"
              :precision="2"
              placeholder="Digite a porcentagem de perda no processo"
              autocomplete="off"
              :required="!finalizado"
              :disabled="finalizado"
            />
          </b-input-group>
        </template>
      </b-form-group>
    </template>
  </KTCard>
</template>

<script>
import ApiMixin from "@/core/mixins/api.mixin.js";
import HelpersMixin from "@/core/mixins/helpers.mixin.js";

export default {
  name: "PipProdutoForm",
  props: {
    idplano_investimento: {
      required: true
    },
    idpip_produto: {
      default: null
    }
  },
  mixins: [ApiMixin, HelpersMixin],
  data() {
    return {
      page_options: {
        icon: "la la-apple-alt",
        title: "Produto",
        description_pp: `Cadastre o produto bem como a unidade de medida,
          ou seja, a unidade em que será realizada a comercialização.
          Caso um mesmo produto tenha duas ou mais formas de
          comercialização (kg, saco, fardo ou tonelada), cadastre cada
          uma como um produto. Lembre- se que para cada produto será
          necessário elaborar o plano de vendas e determinar o custo
          de produção. Sendo assim, recomendamos cadastrar num primeiro
          momento somente os produtos com maior potencial de comercialização.`,
        description_pn: `Cadastre o produto bem como a unidade de medida, ou seja,
          a unidade em que será realizada a comercialização. Todo processo produtivo,
          principalmente os que envolvem matéria-prima como, frutas, leite, carnes,
          etc estão sujeitas a perdas durante o processamento. Atribua um percentual
          de acordo com a atividade e tecnologia de produção a ser empregada. Mais
          detalhes na Ajuda (?).`
      },
      form: {},
      tipo_plano_investimento: null,
      finalizado: true
    };
  },
  computed: {
    apiResource() {
      return `pip/${this.idplano_investimento}/produto`;
    },
    manual() {
      return {
        session: "produto",
        tipo: this.tipo_plano_investimento
      };
    },
    isEdit() {
      return !!this.idpip_produto;
    },
    parentRoute() {
      return {
        name: "pip_produto_list",
        params: { idplano_investimento: this.idplano_investimento }
      };
    },
    isPP() {
      return parseInt(this.tipo_plano_investimento) === 1;
    },
    page() {
      return {
        ...this.page_options,
        description: this.isPP
          ? this.page_options.description_pp
          : this.page_options.description_pn
      };
    }
  },
  methods: {
    getPageData() {
      const requests = [this.Api.GetData(this.idpip_produto)];

      const resolveFuntion = (res) => {
        // Requests index's
        const R_GETDATA = 0;

        this.form = res[R_GETDATA].produto ?? {};
        this.tipo_plano_investimento = res[R_GETDATA].tipo_plano_investimento;
        this.finalizado = res[R_GETDATA].finalizado;

        this.initManual(this.manual.session, this.manual.tipo);
      };

      this.ApiGetPageData(requests, resolveFuntion);
    },
    onSubmit() {
      this.ApiSubmit(this.idpip_produto, this.form);
    }
  },
  mounted() {
    this.getPageData();
  }
};
</script>
